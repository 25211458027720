import React, { useState } from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

import './Clients.css'

import clientLogo from './../../data/ClientLogo'

const Clients = () => {

    const [photoIndex, setPhotoIndex] = useState(0)
    const [isOpen, setIsOpen] = useState(false)

    return (
        <Container id="clients">
            <Row style={{ marginBottom: '4rem' }} className="no-gutters mt-4">
                {
                    clientLogo.map(img => (
                        <Col style={{ cursor: 'pointer' }} xs md={3} lg={3} onClick={() => setIsOpen(true)} >
                            <div className="client-box text-center client-gutter">
                                <img className="img-fluid" onContextMenu={(e) => (e.preventDefault())} id="client-logo-img" src={img} alt="Clients Logo" />
                            </div>
                        </Col>
                    ))
                }
            </Row>

            {isOpen && (
                <Lightbox
                    onContextMenu={(e) => (e.preventDefault())}
                    mainSrc={clientLogo[photoIndex]}
                    nextSrc={clientLogo[(photoIndex + 1) % clientLogo.length]}
                    prevSrc={clientLogo[(photoIndex + clientLogo.length - 1) % clientLogo.length]}
                    onCloseRequest={() => setIsOpen(false)}
                    onMovePrevRequest={() =>
                        setPhotoIndex((photoIndex + clientLogo.length - 1) % clientLogo.length)
                    }
                    onMoveNextRequest={() =>
                        setPhotoIndex((photoIndex + 1) % clientLogo.length,)
                    }
                />
            )}
        </Container>
    );
};

export default Clients;