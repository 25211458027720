import clientLogo1 from '../../src/images/clients-logo/1.jpg'
import clientLogo2 from '../../src/images/clients-logo/2.png'
import clientLogo3 from '../../src/images/clients-logo/3.jpg'
import clientLogo4 from '../../src/images/clients-logo/4.png'
import clientLogo5 from '../../src/images/clients-logo/5.png'
import clientLogo6 from '../../src/images/clients-logo/6.png'
import clientLogo7 from '../../src/images/clients-logo/7.jpg'
import clientLogo8 from '../../src/images/clients-logo/8.jpg'
import clientLogo9 from '../../src/images/clients-logo/9.png'
import clientLogo10 from '../../src/images/clients-logo/10.png'
import clientLogo11 from '../../src/images/clients-logo/11.jpg'
import clientLogo12 from '../../src/images/clients-logo/12.png'
import clientLogo13 from '../../src/images/clients-logo/13.png'
import clientLogo14 from '../../src/images/clients-logo/14.jpg'
import clientLogo15 from '../../src/images/clients-logo/15.png'
import clientLogo16 from '../../src/images/clients-logo/16.png'
import clientLogo17 from '../../src/images/clients-logo/17.png'
import clientLogo18 from '../../src/images/clients-logo/18.png'
import clientLogo19 from '../../src/images/clients-logo/19.png'
import clientLogo20 from '../../src/images/clients-logo/20.png'
import clientLogo21 from '../../src/images/clients-logo/21.png'
import clientLogo22 from '../../src/images/clients-logo/22.jpg'
import clientLogo23 from '../../src/images/clients-logo/23.jpg'
import clientLogo24 from '../../src/images/clients-logo/24.png'
import clientLogo25 from '../../src/images/clients-logo/25.png'
import clientLogo26 from '../../src/images/clients-logo/26.png'
import clientLogo27 from '../../src/images/clients-logo/27.png'
import clientLogo28 from '../../src/images/clients-logo/28.png'
import clientLogo29 from '../../src/images/clients-logo/29.jpg'
import clientLogo30 from '../../src/images/clients-logo/30.png'
import clientLogo31 from '../../src/images/clients-logo/31.png'
import clientLogo32 from '../../src/images/clients-logo/32.jpg'
import clientLogo33 from '../../src/images/clients-logo/33.jpg'
import clientLogo34 from '../../src/images/clients-logo/34.png'
import clientLogo35 from '../../src/images/clients-logo/35.jpg'
import clientLogo36 from '../../src/images/clients-logo/36.jpg'
import clientLogo37 from '../../src/images/clients-logo/37.png'
import clientLogo38 from '../../src/images/clients-logo/38.JPG'
import clientLogo39 from '../../src/images/clients-logo/39.png'
import clientLogo40 from '../../src/images/clients-logo/40.png'

const clientLogo = [clientLogo1, clientLogo2, clientLogo3, clientLogo4, clientLogo5, clientLogo6, clientLogo7, clientLogo8, clientLogo9, clientLogo10, clientLogo11, clientLogo12, clientLogo13, clientLogo14, clientLogo15, clientLogo16, clientLogo17, clientLogo18, clientLogo19, clientLogo20, clientLogo21, clientLogo22, clientLogo23, clientLogo24, clientLogo25, clientLogo26, clientLogo27, clientLogo28, clientLogo29, clientLogo30, clientLogo31, clientLogo32, clientLogo33, clientLogo34, clientLogo35, clientLogo36, clientLogo37, clientLogo38, clientLogo39, clientLogo40]

export default clientLogo